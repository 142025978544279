import { MicroFlowExecuteTypes } from '@vanguard/shared/enums/microFlowExecuteTypes';
import { BackendService } from './backend.service';
import { CommonhttpService } from './commonhttp.service';
import { Injectable, EventEmitter } from "@angular/core";
import { map } from 'rxjs/operators';
import { environment as configs } from 'environments/environment';
import { UntypedFormGroup } from '@angular/forms';
import { ConnectorTypes } from '@vanguard/config/connectorsTypes';
import { AppConstants } from '@vanguard/config/appConstants';
import { CommonUtility } from '@vanguard/shared/services/commonUtility.service'
import { isArray } from 'util';
import shortid from 'shortid';
import * as FileSaver from "file-saver";
import { interval, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MicroflowBlocking } from '../components/MicroflowBlocking/microflow-bocking/micro-flowblocking';

@Injectable()
export class MicroFlowService {
  changeInVerifications = new EventEmitter();
  public _microflowCount=0;
  private _microFlowConfig:any={};
  mySubscription!: Subscription
  private _totalMicroFlow=0;
  public _microflowInterval;
  public displayMessage='';
  public countPolling=0;
  public finalCount=0;
  public screenBlocking=false;
  public responseFlag = new Subject<any>(); //Response flag to check success or error messages
  fieldService: any;
  constructor(private commonApiService: CommonhttpService, private backendService: BackendService, private commonUtility: CommonUtility,public dialog: MatDialog) {
  }

  public getConnectorsDependencyFields(microFlowConfig, fieldService?, onlyVerifiable?) {
    // Find dependency fields for connectors in the microflow
    const connectorDependencyFields = [];
    let fieldKey;
    for (const step of microFlowConfig['steps']) {
      if (step.type === "CONNECTORS") {
        for (const entity of step['entities']) {
          const requestMap = entity['requestMap'];
          if (requestMap) {
            for (const entry of requestMap) {
              // In case field is marked as static, do not resolve as dependent key it will be resolved on server side connector module
              if ((entry.static && entry.static == false) || !entry.static) {

                if (entry.key === 'form_fields') {
                  const fieldsKeys = entry.value.split(',');
                  connectorDependencyFields.push(...fieldsKeys);
                } else {
                  fieldKey = entry.value;
                }
                if (onlyVerifiable) {
                  if (this.isFieldVerifiable(fieldKey, fieldService)) {
                    connectorDependencyFields.push(fieldKey);
                  }
                } else {
                  connectorDependencyFields.push(fieldKey);
                }
              }
            }
          }
          const parameterMap = entity['parameterMap'];
          if (parameterMap) {
            for (const entry of parameterMap) {
              // In case field is marked as static, do not resolve as dependent key it will be resolved on server side connector module
              if ((entry.static && entry.static == false) || !entry.static) {
        
                if (entry.key === 'form_fields') {
                  const fieldsKeys = entry.value.split(',');
                  connectorDependencyFields.push(...fieldsKeys);
                } else {
                  fieldKey = entry.value;
                }
                if (onlyVerifiable) {
                  if (this.isFieldVerifiable(fieldKey, fieldService)) {
                    connectorDependencyFields.push(fieldKey);
                  }
                } else {
                  connectorDependencyFields.push(fieldKey);
                }
              }
            }
          }
        }
      }
    }
    return connectorDependencyFields;
  }


  public async executeMicroFlow(microFlowConfig, applicationModel, form: UntypedFormGroup, fieldService, microflowExecuteType: MicroFlowExecuteTypes, taskId, buttonKey?, buttonVerficationEnabled?, resIndicator?, isDirectMicroflowCall?) {
    try {
      this._microflowCount++;
      const microFlowId = microFlowConfig['microFlowId'];
      if(microFlowConfig.initiateMessage && microFlowConfig.initiateMessage.enabled ){
        this.displayMessage=microFlowConfig.initiateMessage.message;
      }
      if (microFlowConfig.screenBlocking && this._microflowCount==1 && isDirectMicroflowCall!=true) {
        this.screenBlocking=true
        this.dialog.open(MicroflowBlocking, {
          width: '300px',
          height: '123px',
          data:{
              timeForExecution:this._microflowInterval,
          },
          disableClose: true
        });
      } else if(!microFlowConfig.screenBlocking) {
        this.screenBlocking=false
        this.dialog.closeAll();
      }
      // Find dependency fields for connectors in the microflow
      const connectorDependencyFields = this.getConnectorsDependencyFields(microFlowConfig);

      // Populate request payload
      let requestPayload = {};
      for (const key of connectorDependencyFields) {
        if (key === "taskId" && taskId) {
          requestPayload[key] = taskId;
        } else if (form.controls.hasOwnProperty(key)) {
          requestPayload[key] = form.controls[key].value;
        } else if (applicationModel.hasOwnProperty(key)) {
          requestPayload[key] = applicationModel[key];
        } else if (key.match(/[{},:]/g)) {
          if (typeof key == 'string') {
            if (key.match(/[,]/g)) {
              let array = new Array();
              array = key.replace(/[{}""]/g, '').split(",");
              array.forEach(element => {
                const keydata = element.split(':')
                const fieldKey = String(keydata[1]).trim();
                if (form.contains(fieldKey)) {
                  requestPayload[keydata[0]] = form.controls[fieldKey].value;
                } else if (applicationModel[fieldKey]) {
                  requestPayload[keydata[0]] = applicationModel[fieldKey];
                }
              });
            }
            else {
              const keydata = key.replace(/[{}""]/g, '').split(':')
              const fieldKey = String(keydata[1]).trim();
              if (form.contains(fieldKey)) {
                requestPayload[keydata[0]] = form.controls[fieldKey].value;
              } else if (applicationModel[fieldKey]) {
                requestPayload[keydata[0]] = applicationModel[fieldKey];
              }
            }
          }

        }else{
          requestPayload[key] = this.commonUtility.getFieldValue(key, applicationModel);
        }

      }
      // Execute micro flow and get microflow result
      const microFlowResult = await (new Promise((resolve, reject) => {
        this.initiateMicroFlowRequest(microFlowId, requestPayload, fieldService && fieldService.componentId ? fieldService.componentId : null, applicationModel['application_id'], isDirectMicroflowCall, applicationModel?.product_code).subscribe(async (response) => {
          console.log(response);
          if(response.code==200){
            resIndicator ? form.controls[buttonKey].patchValue(true) : '';
            if(microFlowConfig.successMessage && microFlowConfig.successMessage.enabled ){
              this.displayMessage=microFlowConfig.successMessage.message;
          }
          if(microFlowConfig.enablePolling && microFlowConfig.enablePolling.enabled){
            if (
              microFlowConfig.linkedMicroflow &&
              !microFlowConfig.linkedMicroflow.enabled
            ) {
            this.responseFlag.next("close");
            this.closePollingMicroflow()
            } else {
              this.responseFlag.next(true);
              this.initiatePollingMicroflowService(
                microFlowConfig.linkedMicroflow.microFlow,
                microFlowConfig.enablePolling.triesCount,
                microFlowConfig.enablePolling.sleepTime,
                fieldService
              );
            const response = await this.executePollingMicroflow(applicationModel,form,taskId);
            resolve(response);
            }
        }else{
          if(this.dialog){
            this._microflowInterval=3;
            this.responseFlag.next("close");
            if(this.mySubscription)
            this.mySubscription.unsubscribe();
            setTimeout(() => {
              this.dialog.closeAll();
            }, 3000);
            this._microflowCount = 0;
          }
        }
          }
          if((response['data']) ){
            if(microFlowConfig && microFlowConfig.enablePolling && microFlowConfig.enablePolling.enabled){
              this.responseFlag.subscribe(d=> {
                if(d=='close')
                resolve(response['data'])
              })
            }
            else {
              resolve(response['data']);
            }
          }
          // reject();
        }, (err) => {
          resIndicator ? form.controls[buttonKey].patchValue(false) : '';
          if(microFlowConfig.errorMessage && microFlowConfig.errorMessage.enabled ){
            this.displayMessage=microFlowConfig.errorMessage.message;
          }else{
            this.displayMessage="Microflow returned error"
          }
        //   if (
        //     microFlowConfig.linkedMicroflow &&
        //     !microFlowConfig.linkedMicroflow.enabled &&
        //     microFlowConfig.enablePolling && microFlowConfig.enablePolling.enabled && this._microflowCount==1
        //   ) {
        //     this.initiatePollingMicroflowService(
        //       microFlowConfig,
        //       microFlowConfig.enablePolling.triesCount,
        //       microFlowConfig.enablePolling.sleepTime,
        //       fieldService
        //     );
        //     this.executePollingMicroflow(applicationModel,form,taskId);
        //     this.responseFlag.next(true);
        // }
          reject(err);
        });
      }).catch(error => {
        return error;
      }));
      if (microflowExecuteType === MicroFlowExecuteTypes.BUTTON || microflowExecuteType === MicroFlowExecuteTypes.ONLOAD) {
        const result = {};
        if (microFlowResult && microFlowResult['actions']) {
          // Handle application update
          let applicationUpdate = {};
          if (microFlowResult['actions'][ConnectorTypes.LOOKUP]) {
            applicationUpdate = this.convertArrayToObject(microFlowResult['actions'][ConnectorTypes.LOOKUP]);
          } else {
            applicationUpdate = microFlowResult['actions'][ConnectorTypes.APPLICATION_UPDATE];
          }
          if (applicationUpdate) {
              this.handleApplicationUpdate(applicationModel, applicationUpdate, form, fieldService);
              result['data'] = applicationUpdate;
              // Tick mark on button
              if (buttonVerficationEnabled) {
                const connectorVerifiableDependencyFields = this.getConnectorsDependencyFields(microFlowConfig, fieldService, true);
                if (!connectorVerifiableDependencyFields.length) {
                  if (applicationUpdate["output"]) {
                    applicationModel['verifications'] = applicationModel['verifications'] || [];
                    const fieldVerification = {
                      key: buttonKey,
                      isVerified: applicationUpdate["success"],
                      output: (isArray(applicationUpdate["output"]) && applicationUpdate["output"].length) ? applicationUpdate["output"][0]["data"] : []
                    };
                    applicationModel['verifications'].push(fieldVerification);
                  }
                }
              }
          }

          // Handle preview file
          const previewFile = microFlowResult['actions'][ConnectorTypes.PREVIEW_FILE];
          if (previewFile) {
            result['previewFile'] = previewFile;
          }

          // Handle verifications
          const fieldsVerified = microFlowResult['actions'][ConnectorTypes.VERIFY_FIELDS];
          if (fieldsVerified && Array.isArray(fieldsVerified) && fieldsVerified.length > 0) {

            const [numberOfVerified, numberOfFields] = this.handleVerifications(applicationModel, fieldsVerified, fieldService);

            // Emit change in verifications
            this.changeInVerifications.emit();

            const isAllFieldsVerified = (numberOfVerified > 0 && numberOfVerified === numberOfFields);

            result['isAllFieldsVerified'] = isAllFieldsVerified;
          }

          // Download file
          const fileDetails = microFlowResult['actions'][ConnectorTypes.DOWNLOAD_FILE];
          if (fileDetails && fileDetails['fileBuffer'] && fileDetails['fileBuffer']['data']) {
            const blob = new Blob([new Uint8Array(fileDetails['fileBuffer']['data'])], {
              type: fileDetails['fileType']
            });
            FileSaver.saveAs(blob, fileDetails['fileName']);
          }
          return result;
        } else {
          return microFlowResult;
        }
      } else if (microflowExecuteType === MicroFlowExecuteTypes.LOOKUP) {
        return microFlowResult['actions'][ConnectorTypes.LOOKUP];
      }
      return;
    } catch (err) {
      throw err;
    }
  }

  public handleVerifications(applicationModel, fieldsVerified, fieldService) {
    if (!applicationModel['verifications']) {
      applicationModel['verifications'] = [];
    }
    const verifications = applicationModel['verifications'];

    // Find if an entry already exist for the fields which are verified
    let numberOfVerified = 0;
    let numberOfFields = 0;
    for (const field of fieldsVerified) {
      if (this.isFieldVerifiable(field.key, fieldService)) {
        numberOfFields++;
        if (field.isVerified) {
          numberOfVerified++;
        }
      } else {
        // Skip the field if it is not verifiable
        continue;
      }
      let existingEntry = verifications.filter((verification) => verification.key === field.key);
      if (existingEntry && Array.isArray(existingEntry) && existingEntry.length > 0) {
        existingEntry = existingEntry[0];
        // Replace the existing entry with the new result
        verifications[verifications.indexOf(existingEntry)] = field;
      } else { // Add the new verification from micro flow result
        verifications.push(field);
      }
    }
    return [numberOfVerified, numberOfFields];
  }

  public async handleApplicationUpdate(applicationModel, applicationUpdate, form?, fieldService?) {
    const updateKeys = Object.keys(applicationUpdate);
    const multiTableConfigurations = [];
    const multiTableKeys = [];
    const otherKeys = [];
    let multiDataSuppressConv:any = false
    for (const updateKey of updateKeys) {
      // Update application model
      if (updateKey === 'file') {
        if(!applicationModel['filesUpload']) {
          applicationModel['filesUpload'] = [];
        }
        applicationModel['filesUpload'].push(applicationUpdate[updateKey]);
      }
      if(!multiDataSuppressConv){
      //Check if the response of the key is array if array then it is multidata value 
      if (Array.isArray(applicationUpdate[updateKey]) && !applicationUpdate['suppressConvUI']) {
        if (form.controls.hasOwnProperty(updateKey)) { //check if already present in form 
          const fieldDetail = this.backendService.getField(updateKey);
            let multiDataValue = await this.formatExistingMultiDataValue(applicationUpdate,updateKey, applicationModel[updateKey],fieldDetail); //format the response into required structure
            form.controls[updateKey].setValue(multiDataValue); //set the multidata value to form 
            applicationModel[updateKey] = multiDataValue; //Update the model 
        }  else {
            //If the key is not present in form 
            let mulitdataValue = await this.createMultiDataValue(applicationUpdate,updateKey); //Take the response and stucture it according to multidata
            applicationModel[updateKey] = mulitdataValue; //Update the model
        }
      }
      else if(applicationUpdate['suppressConvUI']){
        let newfield = applicationUpdate['suppressConvUI'][0];
        for (let nfield of Object.keys(newfield)) {
            if (form.controls.hasOwnProperty(nfield)) {
                form.controls[nfield].setValue(newfield[nfield]); //update the form and model
                applicationModel[nfield] = newfield[nfield];
            } else {
              applicationModel[nfield] = newfield[nfield];
            }
        }
        multiDataSuppressConv = true;
      } else {
        applicationModel[updateKey] = applicationUpdate[updateKey];
       }
      }
      let fieldConfiguration;
      if (fieldService) {
        fieldConfiguration = fieldService.fieldsConfig[updateKey];
      }
      if (fieldConfiguration && fieldConfiguration.groupKey && fieldConfiguration.groupType && fieldConfiguration.groupType === 'CONTAINER') {
        // Find multi table keys and add it to multiTableConfigurations
        multiTableConfigurations.push(fieldConfiguration);
        multiTableKeys.push(updateKey);
      } else {
        otherKeys.push(updateKey);
      }
    }
    if (form) {
      // For non multi table fields
      for (const key of otherKeys) {
        // Check if there is any form control created for the update
        if (form.controls.hasOwnProperty(key)) {
          // If field type is radio/dropdown and value to update is an array
          // then instead of updating form control value, field property options
          // will be updated
          let fieldType;
          if (fieldService && fieldService.fieldsConfig &&
            fieldService.fieldsConfig[key] &&
            fieldService.fieldsConfig[key].field_type_details) {
            fieldType = fieldService.fieldsConfig[key].field_type_details.type;
          }
          if (['dropdown', 'radio'].includes(fieldType) &&
            Array.isArray(applicationUpdate[key])) {
            this.updateProperityOptions(fieldService.fieldsConfig[key], applicationUpdate[key], fieldService, applicationModel['application_id']);
          } else if(fieldType!=='multi_data'){ // else if the fieldtype is not multi_data, update the form
            // Else update the form control
            form.controls[key].setValue(applicationUpdate[key]);
            form.controls[key].markAsDirty();
          }
        }
      }

      // For multi table fields
      if (multiTableConfigurations.length > 0) {
        let updatedForm;
        // For user step
        if (fieldService.serviceType === 'USER') {
          // Create a dummy form using only multi table fields
          // and replace the form controls in current form with this new form
          const filteredFieldsMap = new Map<string, any[]>();
          filteredFieldsMap.set(applicationModel['application_id'], multiTableConfigurations);
          updatedForm = fieldService.customFormBuilder(filteredFieldsMap);
          // Replace current form control with new form control created
          for (const key of multiTableKeys) {
            try {
              form.controls[key] =
                updatedForm.controls[applicationModel['application_id']].controls[key];
            } catch (err) {
              // console.log(err);
            }
          }
        }
        // For customer step - TBD
        // else {
        //   updatedForm = fieldService.customFormBuilder(multiTableConfigurations, applicationModel);
        // }
      }
    }
  }

  public handleApplicationsCreation(applicationsToCreate) {
    const applications = Object.keys(applicationsToCreate);
    // Sort applications by id
    applications.sort((applicationId1, applicationId2) => {
      if (applicationId1 < applicationId2) { return -1; }
      if (applicationId1 > applicationId2) { return 1; }
      return 0;
    });
    for (const application of applications) {
      // Get applicant type
      const applicantType = applicationsToCreate[application].applicant;
      // If the applicant type is secondary, add it to joint model
      if (applicantType === AppConstants.SECONDARY_APPLICANT) {
        if (!this.backendService.jointModel) {
          this.backendService.jointModel = [];
        }
        this.backendService.jointModel.push(applicationsToCreate[application]);
      }
    }
  }

  public isFieldVerifiable(fieldKey, fieldService) {
    const fieldConfig = fieldService.fieldsConfig[fieldKey];
    if (fieldConfig && fieldConfig.hasOwnProperty('field_type_details') &&
      fieldConfig.field_type_details.hasOwnProperty('verification_field')) {
      return fieldConfig.field_type_details.verification_field;
    }
    return false;
  }

  public initiateMicroFlowRequest(microFlowId, requestPayload, componentId?, application_id?, isDirectMicroflowCall?, product_code?: string) {
    const body = JSON.stringify(requestPayload);
    let headers = {};
    // Attach component id as header to microflow if passed
    if (componentId) {
      headers['componentid'] = componentId;
    }
    if (application_id) {
      headers['application_id'] = application_id;
    }
    headers['entityid'] = 'default';
    let encRequestInfo: any = body;
    if(isDirectMicroflowCall){
      if(product_code == 'GNL_1h5m9sfsu'){
        return this.commonApiService.post(`${configs.clientUrl}/v5/microflow/execute/GNL_1h5m9sfsu/${microFlowId}/${application_id}`, encRequestInfo, null, headers)
        .pipe(map((response: any) => { return response; }));
      } else {
        return this.commonApiService.post(`${configs.clientUrl}/v4/microflow/execute/${microFlowId}/${application_id}`, encRequestInfo, null, headers)
        .pipe(map((response: any) => { return response; }));
      }
    } else {
      if(product_code == 'GNL_1h5m9sfsu') {
        return this.commonApiService.post(`${configs.clientUrl}/v4/execute-microflow/GNL_1h5m9sfsu/${microFlowId}`, encRequestInfo, null, headers)
        .pipe(map((response: any) => { return response; }));
      } else {
        return this.commonApiService.post(`${configs.clientUrl}/v3/microflow/execute/${microFlowId}`, encRequestInfo, null, headers)
        .pipe(map((response: any) => { return response; }));
      }
    }
  }

  updateProperityOptions(fieldConfig, propertyOptions, fieldService, application_id?) {
    const properties = [];
    const propertyIdentifier = `${fieldConfig.field_type_details.property_type}${fieldConfig.field_type_details.property}`;
    if (propertyOptions && Array.isArray(propertyOptions) && propertyOptions.length > 0) {
      for (const option of propertyOptions) {
        properties.push({
          property_desc: option,
          property_value: option
        });
      }
    }
    fieldService.updateProperties(propertyIdentifier, properties, application_id);
  }

  convertArrayToObject(data) {
    const output = {};
    for (const obj of data) {
      for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (!(property in output)) {
            output[property] = [];
          }
          output[property].push(obj[property]);
        }
      }
    }
    return output;
  }

   /**
    *  createMultiDataValue : create the response format of multiData based on connector response
    *  Parameter : fields
    */
    async createMultiDataValue(fields,field) {
      try { 
          let templateDetails: any = [];
          let multiDataValue: any = [];
          // Object.keys(fields).forEach(field => {
              fields[field].forEach(record => { //fields[field] is the response from connector 
                  if(record.hasOwnProperty('_entryId')){
                    record.entryId = record._entryId;
                    delete record._entryId;
                  }
                  if (record._type) { //record._type contains templateId according to the format from connector 
                      if (templateDetails && templateDetails.length > 0) { //if templateDetails array is not empty 
                          templateDetails.forEach(template => {
                              if (template.templateId == record._type) { //matches the templateId push obj to the paticular existing template
                                  template.tableData.push(record);
                              } else {
                                  templateDetails.push({ "templateId": record._type, "tableData": [record] }); //create new templateId and push into new obj 
                              }
                          });
                      } else {
                          templateDetails.push({ "templateId": record._type, "tableData": [record] });  //create new templateId and push into new obj 
                      }
                  }
              });
              templateDetails.forEach(template => { //Each template contains sections and section Id format accordingly
                  let obj = {
                      "templateId": template.templateId,
                      "sections": [{ "sectionId": shortid.generate(), tableData: template.tableData }], //Generate a sectionId for template
                  }
                  multiDataValue.push(obj); //Structure according to the multidata format present      
              });
          // });
          return multiDataValue;
      } catch(error){
          console.log(error,'error');
      }
  }

  /**
  *  formatExistingMultiDataValue : Call the function if the fieldKey is already present in the form 
  *  Parameter : fields , existingResponse
  */
  async formatExistingMultiDataValue(fields,field,existingResponse,fieldDetail) {
      try {
          let templateDetails = existingResponse; //already value present for the key 
          // if fieldkey contains _overwrite, reset the tabledata for the template sections which are present
          if(fieldDetail && fieldDetail.field_type_details && fieldDetail.field_type_details.multidataConfig && fieldDetail.field_type_details.multidataConfig.overwrite){
            if (templateDetails?.length > 0) {
              templateDetails.forEach((template: any) => {
                template.sections[0].tableData = [];
              })
            }
          }
          // Object.keys(fields).forEach(field => {
              fields[field].forEach((record,index) => { //Loop through each object 
                  if (record._type) { //Check the templateId is present 
                      if(record.hasOwnProperty('_entryId')){
                        record.entryId = record._entryId;
                        delete record._entryId;
                      }
                      if (templateDetails && templateDetails.length > 0) {
                          templateDetails.forEach(template => {
                              if (template.templateId == record._type) {
                                  let isTableDataPresent: boolean = false;
                                  template.sections[0].tableData.forEach(existingData=>{
                                      if(existingData.entryId == record.entryId){
                                          isTableDataPresent = true;
                                      }
                                  })
                                  if(!isTableDataPresent){
                                  template.sections[0].tableData.push(record) //Push the connector response to the existing response
                                  } else {
                                      const tableDataIndex = template.sections[0].tableData.findIndex(currentData=>currentData.entryId==record.entryId); // check for the data with particular entryId
                                      template.sections[0].tableData[tableDataIndex] = record; //replace the record with new record
                                  }
                              }
                          });
                      }
                  } else if(record.templateId && record.sections && templateDetails && templateDetails[index]){
                    templateDetails[index] = record;
                  }
              // });
          });
          return templateDetails; // return formatted response 
      } catch(error){
          console.log(error,'error');
      }
  }
  async executePollingMicroflow(model,form,componentId){
    console.log(this._microflowInterval);
    const ress = await (new Promise((resolve,reject)=>{
      this.mySubscription=interval(this._microflowInterval*1000+100).subscribe(async d=>{
        try {
            if(Number(this._totalMicroFlow)<this._microflowCount){
                this.closePollingMicroflow();
                reject();
                return ;
            }
          const data=await this.executeMicroFlow(this._microFlowConfig,model,form,this.fieldService,MicroFlowExecuteTypes.BUTTON,componentId);
          if(data && data.data){
            this.closePollingMicroflow();
            resolve(true);
          }
        } catch (error) {
          console.log(error);
        }
      })
    }).catch(error => {
      return error;
    }));
    console.log(ress);
    return ress;
}
 /**
     * @desc: Close microflow after success or trial completed
     */
  closePollingMicroflow(){
    this._microflowInterval=3;
    this.responseFlag.next("close");
    if(this.mySubscription)
    this.mySubscription.unsubscribe();
    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);
    this._microflowCount = 0;
}

initiatePollingMicroflowService(config,totalMicroflow,interval,fieldService){
  this._microFlowConfig=config;
  this._totalMicroFlow=totalMicroflow;
  this._microflowInterval=interval;
  this.responseFlag.next(true)
  this.fieldService=fieldService
}

}
