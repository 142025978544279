import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { UserService } from '@vanguard/shared/services/user.service';

@Injectable()
export class AppResolver implements Resolve<any> {
  private _loginDetails:any = null;

  constructor(public router: Router, public route: ActivatedRoute, private userService: UserService) { }

  async resolve(route: ActivatedRouteSnapshot) : Promise<any> {
    const queryParams = route.queryParams;
    if (!window.location.href.includes('backstage') && !this.userService.isLoggedIn() && queryParams['consumerV4']) {
      window.onmessage = (event) => {
          if(!this._loginDetails){
              this._loginDetails = event.data;
              const userDetails = this.userService.decryptUserData(JSON.parse(this._loginDetails.ud));
              this.userService.refreshToken = JSON.parse(this._loginDetails.rc);
              this.userService.accessToken = JSON.parse(this._loginDetails.ac);
              this.userService.sessionId = JSON.parse(this._loginDetails.sc);
              this.userService.sessionExpiryTime = JSON.parse(this._loginDetails.set);
              this.userService.setUserDetails(userDetails);
          }
      }
      await new Promise(resolve => {setTimeout(resolve, 1000)});
      return of(true);
    } else {
      return of(true);
    }
  }
}
