import { Globals } from 'app/globals';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { ApplicationErrorCodes } from '@vanguard/config/applicationErrorCodes';
import { ToasterService } from './toaster/toaster.service';
import { VGTranslatePipe } from '../pipes/translate.pipe';
import * as forge from 'node-forge';
import { PublicKeyCredential } from '../../config/publicKeyConfig';
import { UserService } from './user.service';
import { AppConstants } from '@vanguard/config/appConstants';
import { Global } from '@vanguard/shared/components/validations-modal/global';
import { inflate, deflate } from 'pako';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class CommonhttpService {
  constructor(
    private http: HttpClient,
    public globals: Globals,
    private toasterService: ToasterService,
    private userService: UserService,
    public global: Global
  ) {
  }

  public formatErrors = (error: any) => {
    const appError = {...error.error, status: error.status}; //storing api status coming in header
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error && error.error instanceof Object) {
        const errorResponse = error.error;
        if (errorResponse &&
          (errorResponse.code === ApplicationErrorCodes.VALIDATION_FAILED.code &&
          errorResponse.message === ApplicationErrorCodes.VALIDATION_FAILED.message) ||
          (errorResponse.code === ApplicationErrorCodes.ERROR_WHILE_SEND_BACK.code &&
          errorResponse.message === ApplicationErrorCodes.ERROR_WHILE_SEND_BACK.message)) {
          const validationErros = errorResponse.data;
          const modalEntries = [];
          for (const validationError of validationErros) {
            modalEntries.push({
              type: 'error',
              description: validationError.label ? (validationError.label + ' - ' + validationError.reason) : validationError.reason
            });
          }
          const vgTranslatePipe = new VGTranslatePipe();
          this.global.openModal(modalEntries, 'We encountered following errors');
        }
      }
    }
    if ((appError && appError.data && appError.data.length) || (appError && appError.message && appError.code)) {
      return throwError(appError);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  /**
 * Common Http GET method for all New Get Api method
 * @param path :string api path
 * @param params :HttpParams request params
 * @param headers :HttpHeaders request headers
 */
  public get(path: string, params: any = {}, headers: any = {}): Observable<any> {
    if (headers) {
      headers = new HttpHeaders(headers);
    }
    return this.http.get(`${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }// public oldGet(path: string, params: HttpParams = new HttpParams()): Observable<any>


  /**
   * Common Http PUT method
   * @param path api path
   * @param body request body
   * @param params request params
   * @param headers request headers
   */
  public put(path: string, body: Object = {}, params: any = {}, headers: any = {}): Observable<any> {
    if (headers) {
      headers = new HttpHeaders(headers);
    }
    return this.http.put(`${path}`, body, { params, headers })
      .pipe(catchError(this.formatErrors));
  }// public oldPut(path: string, body: Object = {}): Observable<any>

  /**
   * Common Http POST method
   * @param path api path
   * @param body request body
   * @param params request params
   * @param headers request headers
   */
  public post(path: string, body: Object = {}, params: any = {}, headers: any = {}): Observable<any> {
    if (headers) {
      headers = new HttpHeaders(headers);
    }
    return this.http.post(`${path}`, body, { params, headers })
      .pipe(catchError(this.formatErrors));
  }// public oldPost(path: string, body: Object = {}): Observable<any>

  /**
   * Common Http DELETE method for all New Delete Api method
   * @param path :string api path
   * @param params :HttpParams request params
   * @param headers :HttpHeaders request headers
   */
  public delete(path: string, params: any = {}, headers: any = {}): Observable<any> {
    if (headers) {
      headers = new HttpHeaders(headers);
    }
    return this.http.delete(`${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }// public oldDelete(path): Observable<any>

  public downloadFile(path: string, params: any = {}): Observable<any> {
    const headers = new HttpHeaders({ responseType: 'blob' });
    return this.http.get(`${path}`, { params, headers })
      .pipe(map(response => response), catchError(this.formatErrors));
  }

  /**
   * Convert key to 32 bit
   * @param secretKey
   * @returns {string}
   */
  getKey(secretKey) {
    return secretKey.substr(0, 32);
  }

  /**
   * Encrypt with cipher and secret Key
   * @param data
   * @param secretKey
   * @returns {hex string|*}
   */
  encryptData(data) {
    try {
      if (typeof data !== "string") data = JSON.stringify(data);
      const compressedData = deflate(data);
      const secretKey = localStorage.getItem('_sk');
      const iv = forge.random.getBytesSync(16);
      const cipher = forge.cipher.createCipher(
        "AES-CBC",
        this.getKey(secretKey)
      );
      cipher.start({ iv: iv });
      cipher.update(forge.util.createBuffer(compressedData.toString(), "utf8"));
      cipher.finish();
      const encrypted = cipher.output;
      // outputs encrypted hex
      const encryptedData = forge.util.bytesToHex(iv) + ":" + encrypted.toHex();
      return encryptedData;
    } catch (e) {
      return e;
    }
  }

  encryptDataV2(data) {
    try {
      if (typeof data !== "string") data = JSON.stringify(data);
      const compressedData = deflate(data).toString();
      const secretKey = localStorage.getItem('_sk');
      const encryptedData = CryptoJS.AES.encrypt(compressedData, secretKey).toString();
      return encryptedData;
    } catch (e) {
      return e;
    }
  }

  /**
   * Decrypt with cipher and secret Key
   * @param data
   * @param secretKey
   * @returns {string|*}
   */
  decryptData(data) {
    try {
      // data = JSON.stringify(data);
      const secretKey = localStorage.getItem('_sk'); // taking secretKey from localstorage instead of access_token
      const textParts = data.split(":");
      const iv = forge.util.hexToBytes(textParts.shift());
      const decipher = forge.cipher.createDecipher(
        "AES-CBC",
        this.getKey(secretKey)
      );
      decipher.start({ iv: iv });
      const encryptedText = forge.util.hexToBytes(textParts.join(":"));
      decipher.update(forge.util.createBuffer(encryptedText));
      decipher.finish(); // check 'result' for true/false
      // outputs decrypted hex
      const tmpStringArr: any = decipher.output.data.split(","); // converting string in to array
      const tmpData: any = new Uint8Array(tmpStringArr); // converting array in to uint8Array buffer
      const decompressedData = JSON.parse(inflate(tmpData, { to: "string" })); // decompressing the data in string format and converting to json format
      return decompressedData;
    } catch (e) {
      this.toasterService.error(
        "Sorry, the response data is tampered or corrupted"
      );
      return data;
    }
  }

  decryptDataV2(data) {
    try {
      const secretKey = localStorage.getItem('_sk'); // taking secretKey from localstorage instead of access_token
      const decryptedData = CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);
      const compressedBinaryData = Uint8Array.from(atob(decryptedData), char => char.charCodeAt(0));      
      const decompressedData = inflate(compressedBinaryData,{to: 'string'});
      return JSON.parse(decompressedData);
     //return result;
    } catch (e) {
      this.toasterService.error('Sorry, the response data is tempered or corrupted');
      return data;
    }
  }
  /**
   * Decrypt with cipher and secret Key With Custom Secret Key
   * @param data
   * @param secretKey
   * @returns {string|*}
   */
  decryptDataWithCustomSecretKey(data, secretKey) {
    try {
      const textParts = data.split(":");
      const iv = forge.util.hexToBytes(textParts.shift());
      const decipher = forge.cipher.createDecipher(
        "AES-CBC",
        this.getKey(secretKey)
      );
      decipher.start({ iv: iv });
      const encryptedText = forge.util.hexToBytes(textParts.join(":"));
      decipher.update(forge.util.createBuffer(encryptedText));
      decipher.finish(); // check 'result' for true/false
      // outputs decrypted hex
      return (decipher.output.toString());
    } catch (e) {
      console.log("decryptDataWithCustomSecretKey",e)
      this.toasterService.error(
        "Sorry, the response data is tampered or corrupted"
      );
      return data;
    }
  }

   /**
   * Validate routes to decrypt or not
   * @param path
   * @returns {boolean|*}
   */
  validateRoutes(path){
    try {
        const allRoutes = new RegExp(AppConstants.DECRYPT_EXCLUDE_ROUTES.join('|'));
        if (allRoutes.test(path)) {
            return true;
        }
        return false;
    } catch (error) {
        throw error;
    }
  }
}
