import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment as configs } from 'environments/environment';
import { SharedModule } from '@vanguard/shared';
import { PagenotfoundComponent } from '@vanguard/shared/components/pagenotfound/pagenotfound.component';
import { UserService } from '@vanguard/shared/services/user.service';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Globals } from 'app/globals';
import { ChartsModule } from 'ng2-charts';
import { IonicModule } from '@ionic/angular';
import { CustomErrorComponent } from '@vanguard/shared/components/customError/customError.component';
import { AppConfigService } from '@vanguard/shared/services/app-config.service';
import { ExpiredLinkErrorComponent } from '@vanguard/shared/components/expiredLinkError/expiredLinkError.component';
import { AppResolver } from './app.resolver';
import { HTTPService } from './modules/core/ui-elements/v4/services/http.service';
import '@latitudenpm/ezee-lend-components';
import { NgxVishwamLibModule } from "ngx-vishwam-lib";
const VishwamConfig = {
API_URL: configs.vishwamSdkUrl
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({uri: `${configs.clientUrl}/v4/graphql`}),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
    }
  };
}

// export function appInit(appConfigService: AppConfigService) {
//   return () => appConfigService.getMapsConfig('Analytics','Google Maps');
// }

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    CustomErrorComponent,
    ExpiredLinkErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    NgxSpinnerModule,
    ApolloModule,
    HttpLinkModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: configs.production }),
    ChartsModule,
    IonicModule.forRoot(),
    NgxVishwamLibModule.forRoot(VishwamConfig),
  ],
  providers: [UserService,
    AppResolver,
    HTTPService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    Globals,
    // AppConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInit,
    //   multi: true,
    //   deps: [AppConfigService]
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
