export const ENDPOINT_UTILS = {
    task: '/v4/tasks/',
    taskCount: '/v4/taskcount',
    steps: 'v4/flow/overview',
    flowDetail: 'config/flow_config_by_id',
    dependencyConfig: 'config/get_fields_config',
    fetchPreviewDetails: 'v4/public/page-preview',
    getStepDetailOverview:'v4/config/getFlowConfig',
    getComponentDetails:'v4/config/flow_configs',
    componentSave:'v3/application/user/save_or_update',
    rejectTask:'/v3/application/action/reject',
    cancelTask:'/v3/application/action/cancel',
    completetask:'v3/application/action/complete_step/user',
    sendBack:'/v3/application/approvals/send_back',
    documents: '/v4/documents',
    query: '/v4/query',
    setupConfig:'/component/setup_config',
    productsList: '/v4/master_configs/products',
    getCaptchaSiteKey: 'setup/configs',
    validateCaptcha: 'v3/captcha/validateCaptcha'
};
