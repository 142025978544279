import { Injectable } from '@angular/core';
import { UserService } from '@vanguard/shared/services/user.service';
import { environment as config } from 'environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HTTPService } from '../v4/services/http.service';
import { TaskService } from '../v4/services/task.service';
import { ENDPOINT_UTILS } from '../v4/utils/end-point.utils';

@Injectable({
  providedIn: 'root',
})
export class UiCustomerDedupeService {
  public isCustomer=false;
  public dedupeSettings;
  public whitelistingSettings;
  public reditectButtonEnabled:boolean = false;
  constructor(private _http: HTTPService, private _taskService: TaskService,
    private userService: UserService
    ) {}

  async getWorkFlowDetails(flowName: string, verNum: number, mode: string) {
    return new Promise(async (resolve, reject) => {
      this.fetchWorkFlowData(flowName, verNum, mode).subscribe((resp:any) => {
        try {
          if (!resp.error) {
            let steps = resp.data.steps;
            this.dedupeSettings=resp.data.dedupeSettings;
            resolve(steps)
          } else {
            reject(resp.error)
          }
        }catch(e){

        }
      })
    })
  }
    fetchWorkFlowData(flowName: any, verNum: any, mode: any) {
      const stepUrl = `${config.adminUrl}/${ENDPOINT_UTILS.steps}?flowName=${flowName}&verNum=${verNum}&mode=${mode}`;
      return this._http.get(stepUrl, '').pipe(map((data) => {
        console.log("getSteps", data)
        return data;
      }), catchError((error) => {
        return throwError(error);
      }));
    }

  createDynamicTask(application_id,fields,isCustomer?) {
    try {
      console.log(isCustomer);
      return this._http
        .post(`${config.clientUrl}/v4/dedupe/${isCustomer?'customer':'prospect'}/${application_id}`, {
            fields
        },'',undefined,false,false,{
          appRefId:this.userService.appRefId,
        },true)
        .toPromise();
    } catch (error) {
      console.log(error);
      return this._http.handleError(error);
    }
  }

  matchIndex(application_id,index,isCustomer?) {
    try {
      return this._http
        .post(`${config.clientUrl}/v4/dedupe/${isCustomer?'customer':'prospect'}/${application_id}/match/${index}`)
        .toPromise();
    } catch (error) {
      return this._http.handleError(error);
    }
  }

  performVerdict(application_id,status,isCustomer?) {
    try {
      return this._http
        .post(`${config.clientUrl}/v4/dedupe/${isCustomer?'customer':'prospect'}/${application_id}/verdict/${status}`)
        .toPromise();
    } catch (error) {
      return this._http.handleError(error);
    }
  }

  performAddComment(application_id,obj,isCustomer?) {
    try {
      return this._http
        .post(`${config.clientUrl}/v4/dedupe/${isCustomer?'customer':'prospect'}/${application_id}/comment`,obj)
        .toPromise();
    } catch (error) {
      return this._http.handleError(error);
    }
  }
}
