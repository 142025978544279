export const environment = {
  production: true,
  encryptPassword: false,
  encryptRequest: false,
  encryptAdminApiRequest: false,
  clientUrl : 'https://invictus-clixcapital-campaign-userportal.ezee.ai/consumerAPI',
  adminUrl : 'https://invictus-clixcapital-campaign-userportal.ezee.ai/adminAPI',
  domain : 'https://invictus-clixcapital-campaign-userportal.ezee.ai',  // Domain on which the application will be deployed
  testUrl: 'https://invictus-clixcapital-campaign-userportal.ezee.ai/consumerAPI',
  notificationUrl: 'https://invictus-clixcapital-campaign-userportal.ezee.ai/',
  decisionEngineUrl: 'https://invictus-clixcapital-campaign-userportal.ezee.ai/decisionEngine',
  videoConferenceUrl: 'wss://identify.sandboxforbank.com/janus/',
  postVideoConferenceUrl: 'https://identify.sandboxforbank.com/api',
  videoRecordingPath: '/home/ubuntu/recording',
  googleApiKey: 'AIzaSyDayIyBgMXnOCrRscNmms2c1y71tAoLzO4',
  loginUrl: 'https://invictus-clixcapital-auth.ezee.ai/auth/realms/clix-prod/protocol/openid-connect/auth?client_id=clix-prod&response_type=code&state=fj8o3n7bdy1op5&redirect_uri=https://invictus-clixcapital-campaign-userportal.ezee.ai',
  uamRedirectUrl: 'https://invictus-clixcapital-campaign-userportal.ezee.ai',
  customerUserPassword: "13232e0442360b3bca1033a3b3c5cc18:1291db1dc2832049f2330408a3a079fa",
  headingFontFamily:'Open Sans',
  contentFontFamily:'SourceSansPro-Regular',
  globalFontSizeContent:'16px',
  immediatePreview: false,
  vishwamSdkUrl: '',
  mid: '',
  repaymentApiKey: '',
  eid: '',
  repaymentUrl: '',
  finvuWebSocketUrl: '',
  enableCompressedWidth: false,
  repaymentCheckoutUrl: ''
};
